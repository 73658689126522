import { Loader } from '@itau-loans-www/shopping/src/components'

import Layout from '../../layouts/Application'
import { useHandleQueryStrings } from './hooks'

const Skipper = () => {
  useHandleQueryStrings()

  return (
    <Layout className="page" hasCookieBar={false}>
      <Loader visible />
    </Layout>
  )
}

export default Skipper
