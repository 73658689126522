import { useCallback, useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { showFeedback, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import { useLocation } from '@reach/router'
import { goTo } from 'utils'

export const useHandleQueryStrings = () => {
  const location = useLocation()
  const {
    changeLeadData,
    leadData: { partner }
  } = useLead()

  const getQueries = (location) => {
    const leadUuid = new URLSearchParams(location?.search).get('lead_uuid')
    const token = new URLSearchParams(location?.search).get('token')

    return {
      leadUuid,
      token
    }
  }

  const handleQueryStrings = useCallback(() => {
    const { leadUuid, token } = getQueries(location)
    const requestBody = {
      key: leadUuid,
      token,
      version: 2,
      source_url: location.href
    }

    datadogRum.addAction('skipper', {
      requestBody
    })

    http.proposal
      .newCreateLead(requestBody)
      .then(({ next_step }) => goTo(next_step))
      .catch(() => {
        goTo('basic-data')
        showFeedback({
          detail: {
            message: '',
            show: false,
            type: ''
          }
        })
      })
  }, [location])

  useEffect(() => {
    if (partner) changeLeadData({ partner: null })

    handleQueryStrings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLeadData, handleQueryStrings])
}
